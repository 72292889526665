/* You can add global styles to this file, and also import other style files */
@import "./assets/css/custom css/variable.scss";

:root {
  --fontFamily: "Montserrat", sans-serif;
  --mainColor: #0e5ba0;
  --buttonColor: #0e5ba0;
  // --mainColor: #0ec6c6;
  --mainColor2: #0e5ba0;
  --optionalColor: #666666;
  --whiteColor: #ffffff;
  --blackColor: #221638;
  --fontSize: 15px;
  --transition: 0.5s;
  --btn-hover: #0e5ba0;
  --redColor: #ea2629;

  // --btn-hover: #3b99ca;
}

body {
  padding: 0;
  margin: 0;

  // text-transform: capitalize;

  font: {
    size: var(--fontSize);
    family: var(--fontFamily);
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

a {
  color: var(--blackColor);
  transition: var(--transition);
  text-decoration: none;
  outline: 0 !important;

  &:hover {
    // color: var(--mainColor) !important;
    text-decoration: none !important;
  }
}

.no-data {
  text-align: left;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}

:focus {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  // color: var(--optionalColor);
  // font-size: var(--fontSize);
  // margin-bottom: 15px;
  // line-height: 1.8;

  // &:last-child {
  //   margin-bottom: 0;
  // }
}

.mr-3 {
  margin-right: 8px;
}

.mr-2 {
  margin-right: 5px;
}

.ptb-100 {
  padding: {
    top: 100px;
    bottom: 100px;
  }
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding: {
    top: 70px;
    bottom: 70px;
  }
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.container {
  max-width: 1230px;
}

.bg-f9f9f9 {
  background-color: #f9f9f9;
}

.bg-f5f5f5 {
  background-color: #f5f5f5;
}

.required {
  color: red !important;
}

.bg-main-color {
  background-color: var(--mainColor2);

  .section-title {
    p {
      color: var(--blackColor);
    }
  }
}

/*section-title*/

.section-title {
  text-align: center;
  max-width: 760px;

  margin: {
    left: auto;
    right: auto;
    bottom: 60px;
  }

  h2 {
    font-size: 30px;
    margin-bottom: 15px;

    span {
      color: var(--mainColor);
    }
  }

  &.text-start {
    position: relative;
    max-width: 100%;

    margin: {
      left: 0;
      right: 0;
      bottom: 40px;
    }

    h2 {
      margin-bottom: 0;
    }

    .link-btn {
      position: absolute;
      display: inline-block;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 600;

      i {
        transition: var(--transition);
        color: var(--blackColor);
      }

      &:hover {
        i {
          margin-left: 5px;
          color: var(--mainColor);
        }
      }
    }
  }
}

/*default-btn*/

.default-btn {
  display: inline-block;
  transition: var(--transition);
  border: none;
  text-align: center;
  border: 1px solid #7c86ad;
  box-shadow: 4px 4px #7c86ad;
  background-color: var(--whiteColor);
  color: var(--blackColor);

  font: {
    size: var(--fontSize);
    weight: 600;
  }

  padding: {
    left: 30px;
    right: 30px;
    top: 13px;
    bottom: 13px;
  }

  // &:hover {
  //   border-color: var(--mainColor);
  //   box-shadow: 4px 4px var(--mainColor);
  //   color: var(--blackColor);
  // }
}

/*form-control*/

.form-control {
  border-radius: 0;
  background-color: #f5f5f5 !important;
  box-shadow: unset !important;
  transition: var(--transition);
  border: none !important;
  height: 30px;
  padding-left: 15px;
  color: var(--blackColor);

  font: {
    size: 14.5px;
    weight: 400;
  }

  &::placeholder {
    color: #999999;
    transition: var(--transition);
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
}

/*keyframes-css*/

@keyframes moveleftbounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes cluster-animation {

  0%,
  100% {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
  }

  50% {
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0.1);
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

// upload image button css
.height {
  height: 75px;

  .profile-box {
    // max-width: 300px;
    position: relative;
    border-radius: 5px;

    img {
      border-radius: 5px;
      border: 2px solid var(--mainColor);
    }

    .file-upload {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 5px;

      // bottom: 15px;
      .bx {
        color: var(--whiteColor) !important;
      }

      .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .inputfile+label {
        color: var(--whiteColor);
        background-color: var(--mainColor);
        display: inline-block;
        margin-bottom: 0;
        transition: var(--transition);
        padding: 5px 25px 0px 48px;
        height: 30px;
        position: relative;
        border-radius: 5px;

        font: {
          size: var(--fontSize);
          weight: 600;
        }
      }

      i {
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16.5px;
        margin-top: -0.5px;
      }

      .inputfile:focus+label,
      .inputfile+label:hover {
        background-color: var(--btn-hover);
        color: var(--whiteColor);
      }

      .inputfile+label {
        cursor: pointer;
      }
    }

    .clear-btn {
      color: red;

      .bx {
        line-height: unset;
      }
    }

    .clear-btn :hover {
      cursor: pointer;
    }
  }
}

/*extra-css*/

.feedback-slides {
  .owl-theme {
    .owl-nav {
      margin-top: 0;
      transition: var(--transition);
      opacity: 0;
      visibility: hidden;

      [class*="owl-"] {
        color: var(--blackColor);
        font-size: 18px;
        margin: 0;
        padding: 0;
        background: var(--whiteColor);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        transition: var(--transition);
        border: 1px solid var(--mainColor);
        position: absolute;
        left: -65px;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: -2px 2px var(--mainColor);

        &.owl-next {
          left: auto;
          right: -65px;
          box-shadow: 2px 2px var(--mainColor);
        }

        &:hover {
          box-shadow: 2px 2px transparent;
          background-color: var(--mainColor);
          color: var(--whiteColor);
        }

        &.disabled {
          cursor: not-allowed;
          background-color: var(--whiteColor);
          color: var(--blackColor);
        }
      }
    }

    &:hover {
      .owl-nav {
        opacity: 1;
        visibility: visible;
      }
    }

    .owl-dots {
      margin-top: 5px;

      .owl-dot {
        span {
          width: 12px;
          height: 12px;
          margin: 0 4px;
          background: #b9b9b9;
          border-radius: 50%;
          transition: var(--transition);
        }

        &:hover,
        &.active {
          span {
            background-color: var(--mainColor);
          }
        }
      }
    }
  }
}

.listings-slides {
  .owl-theme {
    .owl-nav {
      margin-top: 0;
      transition: var(--transition);
      opacity: 0;
      visibility: hidden;

      [class*="owl-"] {
        color: var(--blackColor);
        font-size: 18px;
        margin: 0;
        padding: 0;
        background: var(--whiteColor);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        transition: var(--transition);
        border: 1px solid var(--mainColor);
        position: absolute;
        left: -65px;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: -2px 2px var(--mainColor);

        &.owl-next {
          left: auto;
          right: -65px;
          box-shadow: 2px 2px var(--mainColor);
        }

        &:hover {
          box-shadow: 2px 2px transparent;
          background-color: var(--mainColor);
          color: var(--whiteColor);
        }

        &.disabled {
          cursor: not-allowed;
          background-color: var(--whiteColor);
          color: var(--blackColor);
        }
      }
    }

    &:hover {
      .owl-nav {
        opacity: 1;
        visibility: visible;
      }
    }

    .owl-dots {
      margin-top: 5px;

      .owl-dot {
        span {
          width: 12px;
          height: 12px;
          margin: 0 4px;
          background: #b9b9b9;
          border-radius: 50%;
          transition: var(--transition);
        }

        &:hover,
        &.active {
          span {
            background-color: var(--mainColor);
          }
        }
      }
    }
  }
}

.single-listings-box {
  .listings-image {
    .owl-theme {
      z-index: 3;

      .owl-nav {
        margin-top: 0;
        opacity: 0 !important;
        visibility: hidden !important;
        transition: var(--transition);

        [class*="owl-"] {
          color: var(--blackColor);
          font-size: 14px;
          margin: 0;
          transition: var(--transition);
          padding: 0;
          background: #d1d2d0;
          border-radius: 50%;
          width: 30px;
          box-shadow: unset !important;
          border: none !important;
          height: 30px;
          line-height: 32px;
          text-align: center;
          position: absolute;
          left: 20px;
          top: 63%;
          transform: translateY(-63%);
          z-index: 3;

          &.owl-next {
            left: auto;
            right: 20px;
          }

          &:hover {
            background-color: var(--mainColor);
            color: var(--whiteColor);
          }
        }
      }
    }

    &:hover {
      .owl-theme {
        .owl-nav {
          opacity: 1 !important;
          visibility: visible !important;
        }
      }
    }
  }
}

.main-banner-content {
  form {
    .form-group {
      .ngx-dropdown-container {
        .ngx-dropdown-button {
          border: none;
          height: 60px;
          display: block;
          margin-bottom: 0;
          overflow: unset;
          line-height: 60px;
          min-height: auto;
          border-radius: 0;
          color: #666666;
          background-color: var(--whiteColor);

          font: {
            size: 15px;
            weight: 400;
          }

          padding: {
            left: 30px;
            right: 0;
            bottom: 0;
            top: 0;
          }

          .nsdicon-angle-down {
            right: 0;

            &::before {
              height: 8px !important;
              width: 8px !important;
              top: 3px !important;
              border-color: var(--mainColor);
            }
          }
        }

        .ngx-dropdown-list-container {
          border: none;
          width: 100%;
          margin-bottom: 0;
          margin-top: 0;
          border-radius: 0;
          padding-top: 0;
          padding-left: 15px;
          height: 210px;
          overflow-y: scroll;
          padding-bottom: 15px;
          background-color: var(--whiteColor);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

          .search-container {
            input {
              border-color: #eeeeee !important;
            }
          }

          ul {
            margin-top: 15px !important;
            margin-bottom: 5px !important;
            padding-left: 0 !important;
            text-align: start;

            li {
              position: relative !important;
              color: var(--blackColor) !important;
              transition: var(--transition) !important;
              text-align: start;

              padding: {
                left: 0 !important;
                right: 0 !important;
                top: 15px !important;
                bottom: 0 !important;
              }

              &:first-child {
                padding-top: 0 !important;
              }

              font: {
                size: var(--fontSize) !important;
                weight: 500 !important;
              }

              &:hover {
                color: var(--mainColor) !important;
              }
            }

            &.selected-items {
              li {
                background-color: transparent !important;
                color: var(--mainColor) !important;
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }
}

.banner-content {
  form {
    .form-group {
      .ngx-dropdown-container {
        .ngx-dropdown-button {
          border: none;
          height: 60px;
          display: block;
          margin-bottom: 0;
          overflow: unset;
          line-height: 60px;
          min-height: auto;
          border-radius: 0;
          color: #666666;
          background-color: var(--whiteColor);

          font: {
            size: 15px;
            weight: 400;
          }

          padding: {
            left: 30px;
            right: 0;
            bottom: 0;
            top: 0;
          }

          .nsdicon-angle-down {
            right: 0;

            &::before {
              height: 8px !important;
              width: 8px !important;
              top: 3px !important;
              border-color: var(--mainColor);
            }
          }
        }

        .ngx-dropdown-list-container {
          border: none;
          width: 100%;
          margin-bottom: 0;
          margin-top: 0;
          border-radius: 0;
          padding-top: 0;
          padding-left: 15px;
          height: 210px;
          overflow-y: scroll;
          padding-bottom: 15px;
          background-color: var(--whiteColor);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

          .search-container {
            input {
              border-color: #eeeeee !important;
            }
          }

          ul {
            margin-top: 15px !important;
            margin-bottom: 5px !important;
            padding-left: 0 !important;
            text-align: start;

            li {
              position: relative !important;
              color: var(--blackColor) !important;
              transition: var(--transition) !important;
              text-align: start;

              padding: {
                left: 0 !important;
                right: 0 !important;
                top: 15px !important;
                bottom: 0 !important;
              }

              &:first-child {
                padding-top: 0 !important;
              }

              font: {
                size: var(--fontSize) !important;
                weight: 500 !important;
              }

              &:hover {
                color: var(--mainColor) !important;
              }
            }

            &.selected-items {
              li {
                background-color: transparent !important;
                color: var(--mainColor) !important;
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }
}

.single-footer-widget {
  .languages-switch {
    .ngx-dropdown-container {
      .ngx-dropdown-button {
        border: none !important;
        display: block !important;
        margin-bottom: 0 !important;
        overflow: unset !important;
        height: 30px !important;
        line-height: 30px !important;
        min-height: auto !important;
        border-radius: 3px !important;
        color: var(--mainColor) !important;
        background-color: #f1f1f1 !important;

        font: {
          size: var(--fontSize) !important;
          weight: 500 !important;
        }

        padding: {
          left: 12px !important;
          right: 12px !important;
          bottom: 0 !important;
          top: 1px !important;
        }

        .nsdicon-angle-down {
          right: 20px;

          &::before {
            height: 8px !important;
            width: 8px !important;
            top: 3px !important;
            border-color: #838383;
          }
        }
      }

      .ngx-dropdown-list-container {
        border: none !important;
        border-radius: 3px !important;
        width: 100% !important;
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 0;
        padding-left: 15px !important;
        padding-bottom: 15px;
        background-color: var(--whiteColor) !important;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;

        .search-container {
          input {
            border-color: #eeeeee !important;
          }
        }

        ul {
          margin-top: 15px !important;
          margin-bottom: 5px !important;
          padding-left: 0 !important;
          text-align: start !important;
          background-color: var(--whiteColor) !important;

          li {
            position: relative !important;
            color: var(--blackColor) !important;
            transition: var(--transition) !important;
            text-align: start;

            padding: {
              left: 0 !important;
              right: 0 !important;
              top: 15px !important;
              bottom: 0 !important;
            }

            &:first-child {
              padding-top: 0 !important;
            }

            font: {
              size: var(--fontSize) !important;
              weight: 500 !important;
            }

            &:hover {
              color: var(--mainColor) !important;
            }
          }

          &.selected-items {
            li {
              background-color: transparent !important;
              color: var(--mainColor) !important;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
}

.feedback-area {
  &.bg-image {
    background: {
      // image: url(assets/img/feedback-bg.jpg);
      position: center center;
      size: cover;
      repeat: no-repeat;
    }

    .section-title {
      h2 {
        color: var(--whiteColor);
      }

      p {
        color: var(--whiteColor);
      }
    }
  }
}

.divider2 {
  width: 100%;
  position: absolute;
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #f9f9f9;
  bottom: -100px;
  left: 0;
  z-index: -1;
  transform: scaleY(-1);
}

.divider {
  width: 100%;
  position: absolute;
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #f9f9f9;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.page-title-area {
  padding: {
    bottom: 130px;
    top: 240px;
  }

  background: {
    color: var(--blackColor);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  &.page-title-style-two {
    padding: {
      top: 140px;
      bottom: 120px;
    }
  }
}

.page-title-content {
  text-align: center;

  h2 {
    font-size: 37px;
    margin-bottom: 0;
    color: var(--whiteColor);
  }

  ul {
    padding-left: 0;
    list-style-type: none;

    margin: {
      bottom: 0;
      top: 22px;
    }

    li {
      display: inline-block;
      color: var(--whiteColor);
      font-weight: 500;
      position: relative;

      margin: {
        left: 13px;
        right: 13px;
      }

      a {
        color: var(--whiteColor);
        display: inline-block;
      }

      &::before {
        content: "\f11c";
        position: absolute;
        right: -18px;
        top: 3.2px;

        font: {
          family: Flaticon;
          size: 11px;
        }
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
}

.page-title-bg {
  background-color: #f6f6f6;
  text-align: center;

  padding: {
    top: 80px;
    bottom: 80px;
  }

  h2 {
    margin-bottom: 0;

    font: {
      size: 40px;
      weight: 800;
    }
  }

  form {
    background-color: var(--whiteColor);
    box-shadow: 5px 5px #30eded;
    border-radius: 5px;
    max-width: 900px;

    padding: {
      left: 20px;
      right: 12px;
    }

    margin: {
      left: auto;
      top: 30px;
      right: auto;
    }

    .form-group {
      margin: 0;
      position: relative;
      border-right: 1px solid #30eded;
      margin-left: 12px;

      label {
        margin-bottom: 0;
        position: absolute;
        left: 0;
        top: 19px;
        line-height: 1;
        font-size: 23px;
        color: #646464;
        z-index: 2;
      }

      &.category-select {
        border-right: none;
        padding-right: 20px;
      }

      .form-control {
        border: none !important;
        color: var(--blackColor);
        box-shadow: unset !important;
        background-color: transparent !important;
        height: 60px;
        line-height: 60px;

        font: {
          size: var(--fontSize);
          weight: 400;
        }

        padding: {
          top: 2px;
          bottom: 0;
          left: 30px;
          right: 15px;
        }

        &::placeholder {
          transition: var(--transition);
          color: #666666;
        }

        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }

      .nice-select {
        border-radius: 0;
        border: none;
        float: unset;
        height: 60px;
        line-height: 60px;
        color: #666666;

        font: {
          size: 15px;
          weight: 400;
        }

        padding: {
          left: 30px;
          right: 15px;
          bottom: 0;
          top: 1px;
        }

        &:after {
          border-color: var(--mainColor);
          height: 8px;
          margin-top: -5px;
          right: 0;
          width: 8px;
        }

        .list {
          background-color: var(--whiteColor);
          border-radius: 0;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          list-style-type: none;
          border: none;
          width: 100%;
          height: 210px;
          overflow-y: scroll;

          margin: {
            bottom: 0;
            top: 0;
          }

          padding: {
            left: 0;
            top: 10px;
            bottom: 10px;
          }

          .option {
            line-height: 38px;
            min-height: 38px;
            color: var(--blackColor);
            position: relative;
            transition: var(--transition);

            padding: {
              left: 38px;
              right: 15px;
            }

            font: {
              size: var(--fontSize);
              weight: 500;
            }

            &:hover {
              background-color: var(--mainColor) !important;
              color: var(--whiteColor);

              &::before {
                color: var(--whiteColor);
              }
            }

            &.focus,
            &.selected.focus {
              background-color: transparent !important;
              color: var(--blackColor);
            }

            &::before {
              content: "\ea0f";
              position: absolute;
              left: 12px;
              top: -1px;
              opacity: 0;
              visibility: hidden;
              transition: var(--transition);
              color: var(--mainColor);

              font: {
                family: "boxicons";
                size: 20px;
              }
            }

            &.selected {
              &::before {
                opacity: 1;
                visibility: visible;
              }

              &:hover {
                background-color: var(--mainColor) !important;
                color: var(--whiteColor);

                &::before {
                  color: var(--whiteColor);
                }
              }
            }
          }
        }
      }
    }

    .col-lg-4 {
      .form-group {
        margin-left: 0;
      }
    }

    .submit-btn {
      padding-left: 5px;

      button {
        display: block;
        width: 100%;
        background-color: var(--mainColor);
        border: none;
        color: var(--whiteColor);
        font-size: var(--fontSize);
        font-weight: 600;
        padding: 12px 15px;
        border-radius: 5px;
        transition: var(--transition);

        &:hover {
          background-color: var(--blackColor);
          color: var(--whiteColor);
        }
      }
    }
  }

  &.map-home-area {
    text-align: start;

    padding: {
      top: 0;
      bottom: 0;
    }

    #main-full-map {
      height: 600px;
    }
  }
}

.pagination-area {
  margin-top: 35px;

  .ngx-pagination {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      font-weight: 600;
      color: var(--blackColor);

      margin: {
        left: 4px;
        right: 4px;
      }

      &.disabled {
        color: var(--blackColor);
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: var(--whiteColor);
        padding: 8px 15px;
      }

      a {
        color: var(--blackColor);
        transition: var(--transition);
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: var(--whiteColor);
        padding: 8px 15px;

        &:hover,
        &.current {
          background-color: var(--mainColor);
          color: var(--whiteColor);
        }
      }

      &.current {
        background-color: var(--mainColor);
        color: var(--whiteColor);
        padding: 8px 15px;
      }
    }
  }
}

.widget-area {
  padding-left: 10px;

  &.widget-left-sidebar {
    padding: {
      right: 10px;
      left: 0;
    }
  }

  .widget {
    background-color: var(--whiteColor);
    padding: 20px;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .widget-title {
      margin-bottom: 25px;
      position: relative;
      padding-bottom: 7px;
      border-bottom: 1px solid #eeeeee;

      font: {
        size: 20px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 1px;
        bottom: -1px;
        background-color: var(--mainColor);
      }
    }
  }

  .widget_search {
    form {
      position: relative;

      .screen-reader-text {
        display: none;
      }

      label {
        display: block;
        margin-bottom: 0;
      }

      .search-field {
        height: 50px;
        color: var(--blackColor);
        background-color: #f2f4f5;
        display: block;
        width: 100%;
        border-radius: 5px;
        padding: 2px 0 0 15px;
        border: none;
        transition: var(--transition);
        font-weight: 400;

        &::placeholder {
          color: var(--optionalColor);
          transition: var(--transition);
        }

        &:focus {
          &::placeholder {
            color: transparent;
          }
        }

        &[type="search"]::-ms-clear,
        &[type="search"]::-ms-reveal,
        &[type="search"]::-webkit-search-decoration {
          display: none;
        }
      }

      button {
        border: none;
        background-color: var(--whiteColor);
        color: var(--mainColor);
        height: 40px;
        width: 40px;
        position: absolute;
        right: 5px;
        padding: 0;
        transition: var(--transition);
        top: 5px;
        font-size: 20px;

        i {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover,
        &:focus {
          border-radius: 5px;
          background-color: var(--mainColor);
          color: var(--whiteColor);
        }
      }
    }
  }

  .widget_vesax_posts_thumb {
    position: relative;
    overflow: hidden;
    .past-events {
      max-height: 600px;
      overflow-y: scroll;
    }
    .item {
      overflow: hidden;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .thumb {
        float: left;
        height: 80px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 80px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 80px;
          height: 80px;
          display: inline-block;
          border-radius: 5px;

          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }

          &.bg1 {
            // background-image: url(assets/img/blog/blog4.jpg);
          }

          &.bg2 {
            // background-image: url(assets/img/blog/blog5.jpg);
          }

          &.bg3 {
            // background-image: url(assets/img/blog/blog6.jpg);
          }
        }

        // &::before,
        // &::after {
        //   transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
        //   content: "";
        //   background-color: var(--whiteColor);
        //   position: absolute;
        //   z-index: 1;
        //   top: 50%;
        //   left: 50%;
        //   opacity: 0;
        //   transform: translate(-50%, -50%);
        // }

        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }

        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }

      .info {
        overflow: hidden;
        margin-top: 5px;

        span {
          display: block;
          color: var(--optionalColor);
          text-transform: uppercase;

          margin: {
            top: -2px;
            bottom: 5px;
          }

          font: {
            size: 13px;
          }
        }

        .title {
          margin-bottom: 0;
          line-height: 1.4;

          font: {
            size: 16px;
          }

          a {
            display: inline-block;
          }
        }
      }

      &:hover {
        .thumb {

          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }

  .widget_categories {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        overflow: hidden;
        color: var(--blackColor);
        padding-left: 16px;

        font: {
          weight: 600;
          size: var(--fontSize);
        }

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          background: var(--mainColor);
          height: 8px;
          width: 8px;
          content: "";
          left: 0;
          top: 7px;
          position: absolute;
        }

        a {
          color: var(--blackColor);
          display: inline-block;

          &:hover {
            color: var(--mainColor);
          }
        }

        .post-count {
          font-size: 14px;
          display: inline-block;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .widget_tag_cloud {
    .widget-title {
      margin-bottom: 17px;
    }
  }

  .tagcloud {
    a {
      display: inline-block;
      background: #f5f5f5;
      color: var(--blackColor);
      padding: 7px 15px;
      border: none;
      border-radius: 3px;

      font: {
        weight: 600;
        size: 14px !important;
      }

      margin: {
        top: 8px;
        right: 4px;
      }

      &:hover,
      &:focus {
        color: var(--whiteColor);
        background-color: var(--mainColor);
      }
    }
  }

  .widget_instagram {
    ul {
      padding-left: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;

      margin: {
        bottom: 0;
        left: -5px;
        right: -5px;
        top: -10px;
      }

      li {
        flex: 0 0 50%;
        max-width: 50%;
        text-align: center;

        padding: {
          left: 5px;
          right: 5px;
          top: 10px;
        }

        .box {
          position: relative;
          z-index: 1;
          overflow: hidden;

          .link-btn {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 3;
          }

          i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 30px;
            transition: var(--transition);
            opacity: 0;
            visibility: hidden;
            color: var(--whiteColor);
            z-index: 2;
          }

          img {
            transition: var(--transition);
          }

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            background-color: var(--mainColor);
            transition: var(--transition);
            opacity: 0;
            visibility: hidden;
          }

          &:hover {
            &::before {
              opacity: 0.5;
              visibility: visible;
            }

            img {
              transform: scale(1.3);
            }

            i {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.vesax-grid-sorting {
  .ordering {
    .ngx-dropdown-container {
      width: 215px !important;
      display: inline-block !important;

      .ngx-dropdown-button {
        border: 1px solid #eeeeee !important;
        margin-bottom: 0 !important;
        overflow: unset !important;
        min-height: auto !important;
        border-radius: 5px !important;
        height: 30px !important;
        line-height: 30px !important;
        display: block !important;
        color: var(--blackColor) !important;
        transition: var(--transition) !important;
        background: #f8f8f8 !important;

        font: {
          size: var(--fontSize) !important;
          weight: 600 !important;
        }

        padding: {
          left: 12px !important;
          right: 0 !important;
          bottom: 0 !important;
          top: 0 !important;
        }

        .nsdicon-angle-down {
          right: 12px !important;

          &::before {
            height: 8px !important;
            width: 8px !important;
            top: 3px !important;
            border-color: var(--mainColor) !important;
          }
        }

        &:hover {
          border-color: var(--mainColor) !important;
        }
      }

      .ngx-dropdown-list-container {
        border: none !important;
        width: 100% !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        border-radius: 0 !important;
        padding-top: 0 !important;
        padding-left: 15px !important;
        padding-bottom: 15px !important;
        background-color: var(--whiteColor) !important;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;

        .search-container {
          input {
            border-color: #eeeeee !important;
          }
        }

        ul {
          margin-top: 15px !important;
          margin-bottom: 5px !important;
          padding-left: 0 !important;
          text-align: start !important;

          li {
            position: relative !important;
            color: var(--blackColor) !important;
            transition: var(--transition) !important;
            text-align: start;

            padding: {
              left: 0 !important;
              right: 0 !important;
              top: 15px !important;
              bottom: 0 !important;
            }

            &:first-child {
              padding-top: 0 !important;
            }

            font: {
              size: var(--fontSize) !important;
              weight: 500 !important;
            }

            &:hover {
              color: var(--mainColor) !important;
            }
          }

          &.selected-items {
            li {
              background-color: transparent !important;
              color: var(--mainColor) !important;
              margin-bottom: 2px !important;
            }
          }
        }
      }
    }
  }
}

.billing-details {
  .form-group {
    .ngx-dropdown-container {
      .ngx-dropdown-button {
        border: 1px solid #eeeeee !important;
        margin-bottom: 0 !important;
        overflow: unset !important;
        min-height: auto !important;
        border-radius: 5px !important;
        height: 30px !important;
        line-height: 30px !important;
        display: block !important;
        color: var(--blackColor) !important;
        transition: var(--transition) !important;
        background: #f8f8f8 !important;

        font: {
          size: var(--fontSize) !important;
          weight: 600 !important;
        }

        padding: {
          left: 12px !important;
          right: 0 !important;
          bottom: 0 !important;
          top: 0 !important;
        }

        .nsdicon-angle-down {
          right: 12px !important;

          &::before {
            height: 8px !important;
            width: 8px !important;
            top: 3px !important;
            border-color: var(--mainColor) !important;
          }
        }

        &:hover {
          border-color: var(--mainColor) !important;
        }
      }

      .ngx-dropdown-list-container {
        border: none !important;
        width: 100% !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        border-radius: 0 !important;
        padding-top: 0 !important;
        padding-left: 15px !important;
        padding-bottom: 15px !important;
        background-color: var(--whiteColor) !important;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;

        .search-container {
          input {
            border-color: #eeeeee !important;
          }
        }

        ul {
          margin-top: 15px !important;
          margin-bottom: 5px !important;
          padding-left: 0 !important;
          text-align: start !important;

          li {
            position: relative !important;
            color: var(--blackColor) !important;
            transition: var(--transition) !important;
            text-align: start;

            padding: {
              left: 0 !important;
              right: 0 !important;
              top: 15px !important;
              bottom: 0 !important;
            }

            &:first-child {
              padding-top: 0 !important;
            }

            font: {
              size: var(--fontSize) !important;
              weight: 500 !important;
            }

            &:hover {
              color: var(--mainColor) !important;
            }
          }

          &.selected-items {
            li {
              background-color: transparent !important;
              color: var(--mainColor) !important;
              margin-bottom: 2px !important;
            }
          }
        }
      }
    }
  }
}

.gallery-slides {
  .owl-theme {
    margin: {
      bottom: 35px;
      top: 25px;
    }

    .owl-nav {
      margin-top: 0;
      transition: var(--transition);
      opacity: 0;
      visibility: hidden;

      [class*="owl-"] {
        color: var(--blackColor);
        font-size: 18px;
        margin: 0;
        padding: 0;
        background: var(--whiteColor);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        transition: var(--transition);
        border: 1px solid var(--mainColor);
        position: absolute;
        left: 20px;
        top: 55%;
        transform: translateY(-55%);
        box-shadow: -2px 2px var(--mainColor);

        &.owl-next {
          left: auto;
          right: 20px;
          box-shadow: 2px 2px var(--mainColor);
        }

        &:hover {
          box-shadow: 2px 2px transparent;
          background-color: var(--mainColor);
          color: var(--whiteColor);
        }

        &.disabled {
          cursor: not-allowed;
          background-color: var(--whiteColor);
          color: var(--blackColor);
        }
      }
    }

    &:hover {
      .owl-nav {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.page-title-bg {
  form {
    .form-group {
      .ngx-dropdown-container {
        .ngx-dropdown-button {
          border: none;
          height: 60px;
          display: block;
          margin-bottom: 0;
          overflow: unset;
          line-height: 60px;
          min-height: auto;
          border-radius: 0;
          color: #666666;
          background-color: var(--whiteColor);

          font: {
            size: 15px;
            weight: 400;
          }

          padding: {
            left: 30px;
            right: 0;
            bottom: 0;
            top: 0;
          }

          .nsdicon-angle-down {
            right: 0;

            &::before {
              height: 8px !important;
              width: 8px !important;
              top: 3px !important;
              border-color: var(--mainColor);
            }
          }
        }

        .ngx-dropdown-list-container {
          border: none;
          width: 100%;
          margin-bottom: 0;
          margin-top: 0;
          border-radius: 0;
          padding-top: 0;
          padding-left: 15px;
          height: 210px;
          overflow-y: scroll;
          padding-bottom: 15px;
          background-color: var(--whiteColor);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

          .search-container {
            input {
              border-color: #eeeeee !important;
            }
          }

          ul {
            margin-top: 15px !important;
            margin-bottom: 5px !important;
            padding-left: 0 !important;
            text-align: start;

            li {
              position: relative !important;
              color: var(--blackColor) !important;
              transition: var(--transition) !important;
              text-align: start;

              padding: {
                left: 0 !important;
                right: 0 !important;
                top: 15px !important;
                bottom: 0 !important;
              }

              &:first-child {
                padding-top: 0 !important;
              }

              font: {
                size: var(--fontSize) !important;
                weight: 500 !important;
              }

              &:hover {
                color: var(--mainColor) !important;
              }
            }

            &.selected-items {
              li {
                background-color: transparent !important;
                color: var(--mainColor) !important;
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }
}

.listings-widget-area {
  padding-right: 50px;

  &.widget-right-sidebar {
    padding: {
      right: 0;
      left: 50px;
    }
  }

  .widget {
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 30px;
    padding-bottom: 30px;

    .widget-title {
      font-size: 19px;
      margin-bottom: 25px;
      position: relative;

      &::before {
        font-family: Flaticon;
        content: "\f106";
        position: absolute;
        right: 0;
        top: 0;
        font-size: 19px;
        color: var(--blackColor);
      }
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .widget_filters {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        display: inline-block;
        margin-right: -1px;

        button {
          display: block;
          background-color: transparent;
          border: 1px solid #dedede;
          transition: var(--transition);
          padding: 7px 20px 6px;

          font: {
            size: var(--fontSize);
            weight: 600;
          }

          &:hover,
          &.active {
            background-color: var(--mainColor);
            color: var(--whiteColor);
            border-color: var(--mainColor);
          }
        }
      }
    }
  }
}

//   }
// }

// Error 2

.listings-grid-sorting {
  .ordering {
    .ngx-dropdown-container {
      width: 215px !important;
      display: inline-block !important;

      .ngx-dropdown-button {
        border: 1px solid #eeeeee !important;
        margin-bottom: 0 !important;
        overflow: unset !important;
        min-height: auto !important;
        border-radius: 5px !important;
        height: 30px !important;
        line-height: 30px !important;
        display: block !important;
        color: var(--blackColor) !important;
        transition: var(--transition) !important;
        background: #f8f8f8 !important;

        font: {
          size: var(--fontSize) !important;
          weight: 600 !important;
        }

        padding: {
          left: 12px !important;
          right: 0 !important;
          bottom: 0 !important;
          top: 0 !important;
        }

        .nsdicon-angle-down {
          right: 12px !important;

          &::before {
            height: 8px !important;
            width: 8px !important;
            top: 3px !important;
            border-color: var(--mainColor) !important;
          }
        }

        &:hover {
          border-color: var(--mainColor) !important;
        }
      }

      .ngx-dropdown-list-container {
        border: none !important;
        width: 100% !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        border-radius: 0 !important;
        padding-top: 0 !important;
        padding-left: 15px !important;
        padding-bottom: 15px !important;
        background-color: var(--whiteColor) !important;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;

        .search-container {
          input {
            border-color: #eeeeee !important;
          }
        }

        ul {
          margin-top: 15px !important;
          margin-bottom: 5px !important;
          padding-left: 0 !important;
          text-align: start !important;

          li {
            position: relative !important;
            color: var(--blackColor) !important;
            transition: var(--transition) !important;
            text-align: start;

            padding: {
              left: 0 !important;
              right: 0 !important;
              top: 15px !important;
              bottom: 0 !important;
            }

            &:first-child {
              padding-top: 0 !important;
            }

            font: {
              size: var(--fontSize) !important;
              weight: 500 !important;
            }

            &:hover {
              color: var(--mainColor) !important;
            }
          }

          &.selected-items {
            li {
              background-color: transparent !important;
              color: var(--mainColor) !important;
              margin-bottom: 2px !important;
            }
          }
        }
      }
    }
  }
}

.add-listings-box {
  .file-upload-box {
    .dropzone {
      position: relative;
      border: 2px dashed #eeeeee;
      border-radius: 3px;
      background: rgba(14, 198, 198, 0.03);
      transition: all 0.3s linear;
      display: inline-block;
      width: 100%;
      margin: 0;

      button {
        color: var(--blackColor);
        position: relative;
        padding-top: 52px;

        font: {
          size: 16px;
          weight: 500;
        }

        &::before {
          content: "\eb8a";
          font-family: "boxicons";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          color: var(--mainColor);
          font-size: 35px;
        }
      }

      &:hover {
        border-color: var(--mainColor);
      }
    }
  }
}

/* Max width 767px */

@media only screen and (max-width: 767px) {
  body {
    font-size: 13px;
  }

  p {
    // font-size: 13px;
  }

  .container {
    max-width: 100%;
  }

  .ptb-100 {
    padding: {
      top: 60px;
      bottom: 60px;
    }
  }

  .pt-100 {
    padding-top: 60px;
  }

  .pb-100 {
    padding-bottom: 60px;
  }

  .ptb-70 {
    padding: {
      top: 30px;
      bottom: 30px;
    }
  }

  .pt-70 {
    padding-top: 30px;
  }

  .pb-70 {
    padding-bottom: 30px;
  }

  .section-title {
    max-width: 100%;

    margin: {
      bottom: 40px;
      top: -3px;
    }

    h2 {
      font-size: 23px;
      margin-bottom: 10px;
      line-height: 1.4;
    }

    &.text-start {
      text-align: center !important;

      .link-btn {
        position: relative;
        top: 0;
        transform: translateY(0);
        margin-top: 12px;
      }
    }
  }

  .form-control {
    height: 30px;
    padding-left: 10px;
    font-size: 13px;
  }

  .default-btn {
    font-size: 13px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .navbar-area {
    &.navbar-style-two {
      .vesax-responsive-nav {
        .mean-container {
          a {
            &.meanmenu-reveal {

              // color: var(--whiteColor);
              span {
                // background: var(--whiteColor);
              }
            }
          }
        }
      }
    }
  }

  .vesax-responsive-nav {
    display: block;

    .vesax-responsive-menu {
      position: relative;

      &.mean-container {
        .mean-nav {
          margin-top: 57px;

          ul {
            font-size: 15px;

            li {
              a {
                &.active {
                  color: var(--mainColor);
                }
              }

              li {
                a {
                  font-size: 14.5px;
                }
              }
            }
          }
        }

        .navbar-nav {
          overflow-y: scroll;
          height: 306px;
          box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }

    .mean-container {
      a {
        &.meanmenu-reveal {

          // color: var(--blackColor);
          span {
            // background: var(--blackColor);
          }
        }
      }
    }
  }

  .category-area {
    &.pb-0 {
      padding-bottom: 30px !important;
    }
  }

  .divider {
    display: none;
  }

  .divider2 {
    display: none;
  }

  .feedback-slides {
    .owl-theme {
      .owl-dots {
        display: none;
      }

      .owl-nav {
        margin-top: 0;
        opacity: 1;
        visibility: visible;

        [class*="owl-"] {
          font-size: 16px;
          margin: 0 5px;
          width: 35px;
          height: 35px;
          line-height: 35px;
          position: relative;
          left: 0;
          top: 0;
          transform: unset;

          &.owl-next {
            right: 0;
          }
        }
      }
    }
  }

  .page-title-area {
    padding: {
      bottom: 80px;
      top: 170px;
    }

    &.page-title-style-two {
      padding: {
        top: 90px;
        bottom: 70px;
      }
    }
  }

  .page-title-content {
    h2 {
      font-size: 25px;
    }

    ul {
      margin-top: 15px;
    }
  }

  .page-title-bg {
    padding: {
      top: 60px;
      bottom: 60px;
    }

    h2 {
      font-size: 25px;
    }

    form {
      margin-top: 20px;
      border-radius: 0;
      padding: 15px;
      max-width: 100%;

      .form-group {
        border-right: none;

        margin: {
          left: 0;
          bottom: 10px;
        }

        label {
          left: 10px;
          top: 16px;
          font-size: 18px;

          .flaticon-category {
            position: relative;
            top: 1px;
          }
        }

        .form-control {
          height: 50px;
          line-height: 50px;
          font-size: 13px;
          border: 1px solid #eeeeee !important;

          padding: {
            left: 35px;
            right: 10px;
          }
        }

        &.category-select {
          padding-right: 0;
        }
      }

      .submit-btn button {
        margin-top: 5px;
      }
    }
  }

  .page-title-bg {
    &.map-home-area {
      #main-full-map {
        height: 850px;
      }
    }
  }

  .widget-area {
    padding-left: 0;
    margin-top: 40px;

    &.widget-left-sidebar {
      padding-right: 0;

      margin: {
        top: 0;
        bottom: 40px;
      }
    }

    .widget {
      padding: 15px;

      .widget-title {
        margin-bottom: 20px;
        font-size: 17px;
      }
    }

    .widget_vesax_posts_thumb {
      .item {
        .info {
          .title {
            font-size: 15px;
          }
        }
      }
    }

    .widget_categories {
      ul {
        li {
          font-size: 14px;
        }
      }
    }

    .widget_tag_cloud {
      .widget-title {
        margin-bottom: 13px;
      }
    }

    .tagcloud {
      a {
        font-size: 13px !important;
      }
    }
  }

  .listings-widget-area {
    padding-right: 0;
    margin-bottom: 40px;

    .widget {
      margin-bottom: 25px;
      padding-bottom: 25px;

      .widget-title {
        font-size: 16px;

        &::before {
          font-size: 16px;
        }
      }
    }

    &.widget-right-sidebar {
      padding-left: 0;

      margin: {
        top: 40px;
        bottom: 0;
      }
    }
  }

  .listings-slides {
    .owl-theme {
      .owl-dots {
        display: none;
      }

      .owl-nav {
        margin-top: 0;
        opacity: 1;
        visibility: visible;

        [class*="owl-"] {
          font-size: 16px;
          margin: 0 5px;
          width: 35px;
          height: 35px;
          line-height: 35px;
          position: relative;
          left: 0;
          top: 0;
          transform: unset;

          &.owl-next {
            right: 0;
          }
        }
      }
    }
  }

  .single-listings-box {
    .listings-image {
      .owl-theme {
        .owl-nav {
          opacity: 1 !important;
          visibility: visible !important;

          [class*="owl-"] {
            margin: 0 !important;
            left: 10px;

            &.owl-next {
              left: auto;
              right: 10px;
            }
          }
        }
      }
    }
  }

  .main-banner-content {
    form {
      .form-group {
        .ngx-dropdown-container {
          .ngx-dropdown-button {
            height: 50px;
            font-size: 14px;
            line-height: 49px;
            border: 1px solid #eeeeee;

            padding: {
              left: 35px;
              right: 10px;
            }

            .nsdicon-angle-down {
              right: 15px;
            }
          }
        }
      }
    }
  }

  .banner-content {
    form {
      .form-group {
        .ngx-dropdown-container {
          .ngx-dropdown-button {
            height: 50px;
            font-size: 14px;
            line-height: 49px;
            border: 1px solid #eeeeee;

            padding: {
              left: 35px;
              right: 10px;
            }

            .nsdicon-angle-down {
              right: 15px;
            }
          }
        }
      }
    }
  }

  .page-title-bg {
    form {
      .form-group {
        .ngx-dropdown-container {
          .ngx-dropdown-button {
            height: 50px;
            font-size: 14px;
            line-height: 49px;
            border: 1px solid #eeeeee;

            padding: {
              left: 35px;
              right: 10px;
            }

            .nsdicon-angle-down {
              right: 15px;
            }
          }
        }
      }
    }
  }

  .pagination-area {
    .ngx-pagination {
      li {
        &.disabled {
          padding: 6px 10px;
        }

        &.current {
          padding: 6px 10px;
        }

        a {
          padding: 6px 10px;
        }
      }
    }
  }

  .listings-grid-sorting {
    .ordering {
      .ngx-dropdown-container {
        width: 180px !important;

        .ngx-dropdown-button {
          font-size: 13px !important;
        }
      }
    }
  }

  .main-content {
    .mean-container {
      a {
        &.meanmenu-reveal {
          padding-top: 0;
          margin-top: -5px;
        }
      }
    }

    .vesax-responsive-nav {
      .vesax-responsive-menu {
        &.mean-container {
          .mean-nav {
            margin-top: 41px;
          }
        }
      }
    }
  }
}

/* Min width 576px to Max width 767px */

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 540px;
  }

  .map-home-area {
    form {
      max-width: 540px;

      margin: {
        left: auto;
        right: auto;
      }
    }
  }
}

/* Min width 768px to Max width 991px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 14px;
  }

  p {
    // font-size: 14px;
  }

  .section-title {
    max-width: 580px;
    margin-bottom: 50px;

    h2 {
      margin-bottom: 11px;
    }
  }

  .ptb-100 {
    padding: {
      top: 80px;
      bottom: 80px;
    }
  }

  .pt-100 {
    padding-top: 80px;
  }

  .pb-100 {
    padding-bottom: 80px;
  }

  .ptb-70 {
    padding: {
      top: 50px;
      bottom: 50px;
    }
  }

  .pt-70 {
    padding-top: 50px;
  }

  .pb-70 {
    padding-bottom: 50px;
  }

  .container {
    max-width: 720px;
  }

  .navbar-area {
    &.navbar-style-two {
      .vesax-responsive-nav {
        .mean-container {
          a {
            &.meanmenu-reveal {

              // color: var(--whiteColor);
              span {
                // background: var(--whiteColor);
              }
            }
          }
        }
      }
    }
  }

  .vesax-responsive-nav {
    display: block;

    .vesax-responsive-menu {
      position: relative;

      &.mean-container {
        .mean-nav {
          margin-top: 57px;

          ul {
            font-size: 15px;

            li {
              a {
                &.active {
                  color: var(--mainColor);
                }
              }

              li {
                a {
                  font-size: 14.5px;
                }
              }
            }
          }
        }

        .navbar-nav {
          overflow-y: scroll;
          height: 306px;
          box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }

    .mean-container {
      a {
        &.meanmenu-reveal {
          color: var(--blackColor);

          span {
            // background: var(--blackColor);
          }
        }
      }
    }
  }

  .features-area {
    &.ptb-100 {
      padding-bottom: 50px;
    }
  }

  .divider,
  .divider2 {
    display: none;
  }

  .single-listings-box {
    .listings-image {
      .owl-theme {
        .owl-nav {
          opacity: 1 !important;
          visibility: visible !important;

          [class*="owl-"] {
            margin: 0 !important;
          }
        }
      }
    }
  }

  .listings-slides {
    .owl-theme {
      .owl-dots {
        display: none;
      }

      .owl-nav {
        margin-top: 5px;
        opacity: 1;
        visibility: visible;

        [class*="owl-"] {
          font-size: 16px;
          margin: 0 5px;
          position: relative;
          left: 0;
          top: 0;
          transform: unset;

          &.owl-next {
            right: 0;
          }
        }
      }
    }
  }

  .feedback-slides {
    .owl-theme {
      .owl-dots {
        display: none;
      }

      .owl-nav {
        margin-top: 5px;
        opacity: 1;
        visibility: visible;

        [class*="owl-"] {
          font-size: 16px;
          margin: 0 5px;
          position: relative;
          left: 0;
          top: 0;
          transform: unset;

          &.owl-next {
            right: 0;
          }
        }
      }
    }
  }

  .category-area {
    &.pb-0 {
      padding-bottom: 50px !important;
    }
  }

  .page-title-area {
    padding: {
      bottom: 80px;
      top: 170px;
    }

    &.page-title-style-two {
      padding: {
        bottom: 80px;
        top: 100px;
      }
    }
  }

  .page-title-content {
    ul {
      margin-top: 15px;
    }
  }

  .page-title-bg {
    padding: {
      top: 70px;
      bottom: 70px;
    }

    h2 {
      font-size: 35px;
    }

    form {
      border-radius: 0;
      padding: 25px;
      margin-top: 30px;

      .form-group {
        border-right: none;

        margin: {
          left: 0;
          bottom: 15px;
        }

        label {
          left: 12px;
          top: 16px;
          font-size: 22px;
        }

        .form-control {
          border: 1px solid #eeeeee !important;
          height: 55px;
          line-height: 55px;

          padding: {
            left: 40px;
            right: 10px;
          }
        }

        .ngx-dropdown-container {
          .ngx-dropdown-button {
            height: 55px;
            line-height: 55px;
            border: 1px solid #eeeeee;

            padding: {
              left: 40px;
              right: 10px;
            }

            .nsdicon-angle-down {
              right: 15px;
            }
          }
        }

        &.category-select {
          padding-right: 0;
          margin-left: 15px;
        }
      }

      .submit-btn {
        button {
          display: inline-block;
          width: auto;
          padding: 13px 40px;
          margin-top: 5px;
        }
      }
    }

    &.map-home-area {
      #main-full-map {
        height: 700px;
      }
    }
  }

  .main-banner-content {
    form {
      .form-group {
        .ngx-dropdown-container {
          .ngx-dropdown-button {
            height: 55px;
            line-height: 51px;
            border: 1px solid #eeeeee;

            padding: {
              left: 40px;
              right: 10px;
            }

            .nsdicon-angle-down {
              right: 15px;
            }
          }
        }
      }
    }
  }

  .banner-content {
    form {
      .form-group {
        .ngx-dropdown-container {
          .ngx-dropdown-button {
            height: 55px;
            line-height: 51px;
            border: 1px solid #eeeeee;

            padding: {
              left: 40px;
              right: 10px;
            }

            .nsdicon-angle-down {
              right: 15px;
            }
          }
        }
      }
    }
  }

  .listings-widget-area {
    padding-right: 0;
    margin-bottom: 40px;

    .widget {
      .widget-title {
        font-size: 18px;
      }
    }

    &.widget-right-sidebar {
      padding-left: 0;

      margin: {
        top: 40px;
        bottom: 0;
      }
    }
  }

  .listings-grid-sorting {
    .ordering {
      .ngx-dropdown-container {
        width: 145px !important;
      }
    }
  }

  .widget-area {
    padding-left: 0;
    margin-top: 40px;
  }

  .main-content {
    .mean-container {
      a {
        &.meanmenu-reveal {
          padding-top: 0;
          margin-top: 0px;
        }
      }
    }

    .vesax-responsive-nav {
      .vesax-responsive-menu {
        &.mean-container {
          .mean-nav {
            margin-top: 41px;
          }
        }
      }
    }
  }
}

/* Min width 992px to Max width 1199px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }

  .navbar-area {
    &.navbar-style-two {
      .vesax-responsive-nav {
        .mean-container {
          a {
            &.meanmenu-reveal {

              // color: var(--whiteColor);
              span {
                // background: var(--whiteColor);
              }
            }
          }
        }
      }
    }
  }

  .vesax-responsive-nav {
    .vesax-responsive-menu {
      &.mean-container {
        .mean-nav {
          margin-top: 57px;

          ul {
            font-size: 15px;

            li {
              a {
                &.active {
                  color: var(--mainColor);
                }
              }

              li {
                a {
                  font-size: 14.5px;
                }
              }
            }
          }
        }

        .navbar-nav {
          overflow-y: scroll;
          height: 306px;
          box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }

    .mean-container {
      a {
        &.meanmenu-reveal {
          color: var(--blackColor);

          span {
            // background: var(--blackColor);
          }
        }
      }
    }
  }

  .features-area {
    &.ptb-100 {
      padding-bottom: 70px;
    }
  }

  .divider,
  .divider2 {
    display: none;
  }

  .listings-slides {
    .owl-theme {
      .owl-nav {
        [class*="owl-"] {
          left: -15px;

          &.owl-next {
            left: auto;
            right: -15px;
          }
        }
      }
    }
  }

  .feedback-slides {
    .owl-theme {
      .owl-nav {
        [class*="owl-"] {
          left: -15px;

          &.owl-next {
            left: auto;
            right: -15px;
          }
        }
      }
    }
  }

  .page-title-area {
    padding: {
      bottom: 100px;
      top: 195px;
    }

    &.page-title-style-two {
      padding-bottom: 100px;
    }
  }

  .map-container {
    &.fw-map {
      &.side-full-map {
        margin-bottom: 40px;
        height: auto;
        padding-bottom: 0;

        #main-full-map {
          height: 600px;
        }
      }
    }
  }

  .listings-widget-area {
    padding-right: 40px;

    .widget_filters {
      ul {
        li {
          button {
            font-size: 14px;
          }
        }
      }
    }

    &.widget-right-sidebar {
      padding-left: 40px;
    }
  }

  .main-content {
    .mean-container {
      a {
        &.meanmenu-reveal {
          padding-top: 0;
          // margin-top: -5px;
        }
      }
    }

    .vesax-responsive-nav {
      .vesax-responsive-menu {
        &.mean-container {
          .mean-nav {
            margin-top: 41px;
          }
        }
      }
    }
  }
}

.small-text {
  display: inline-block !important;
  width: 180px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.table-responsive {
  &::-webkit-scrollbar {
    width: 1px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(136, 136, 136, 0.366);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer !important;
  }
}

/* Min width 1200px to Max width 1355px */

@media only screen and (min-width: 1200px) and (max-width: 1355px) {
  .container {
    max-width: 1140px;
  }

  .listings-slides {
    .owl-theme {
      .owl-nav {
        [class*="owl-"] {
          left: -15px;

          &.owl-next {
            left: auto;
            right: -15px;
          }
        }
      }
    }
  }

  .feedback-slides {
    .owl-theme {
      .owl-nav {
        [class*="owl-"] {
          left: -15px;

          &.owl-next {
            left: auto;
            right: -15px;
          }
        }
      }
    }
  }
}

.form-group {
  select {
    // margin-top: 5px;
    // -webkit-appearance: ;
    // border: none !important;
    background-color: #f5f5f5 !important;
  }
}

.inputError {
  .p-inputtext {
    border: 1px solid #dc3545 !important;
  }

  .p-datepicker-trigger {
    border: 1px solid #dc3545 !important;
    border-left: unset !important;
  }
}

.pay_now_btn {
  background-color: #e23636;
  border-radius: 30px;
  color: var(--whiteColor);
  border-color: var(--mainColor);
  border: none;
  position: relative;
  top: -2px;
  margin-left: 2px;
  font-size: 12px;
  padding: 3px 20px;
  cursor: pointer;
}

.upload_btn {
  background-color: #3653e2;
  border-radius: 30px;
  color: var(--whiteColor);
  border-color: var(--mainColor);
  border: none;
  position: relative;
  top: -2px;
  margin-left: 2px;
  font-size: 12px;
  padding: 2px 20px;
  cursor: pointer;
}

.paid_btn {
  background-color: #64bc36;
  padding: 3px 32px;
  border-radius: 30px;
  color: var(--whiteColor);
  border-color: var(--mainColor);
  border: none;
  position: relative;
  top: -2px;
  margin-left: 2px;
  font-size: 12px;
}

/* Min width 1550px */

@media only screen and (min-width: 1550px) {}

/* Primeng Styles */
//.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
// padding: 0.4rem;
//  font-size: 1rem;

.p-datatable .p-datatable-thead>tr>th {
  .p-dropdown {
    background-color: var(--whiteColor);
    border: 1px solid #ced4da;
    // height:98px;

    &:hover {
      border-color: var(--mainColor);
    }
  }
}

.p-inputnumber {
  .p-inputtext {
    height: 30px;
  }
}

.p-toast {
  z-index: 99999 !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  text-transform: capitalize;
}

.p-dropdown {
  transition: none !important;

  &:hover {
    border: 1px solid var(--mainColor) !important;
  }
}

.p-inputtext {
  transition: none !important;
  font-size: var(--fontSize) !important;
  font-weight: 400 !important;
  color: var(--blackColor) !important;

  &:enabled:hover {
    border: 1px solid var(--mainColor);
  }
}

.p-datatable .p-inputtext {
  padding: 0.4rem !important;
  // background: #f5f5f5 !important;
}

.add-listings-box .form-group .form-control,
.add-listings-box .form-group .form-control input {
  &:hover {
    border: 1px solid var(--mainColor) !important;
  }
}

.p-datatable-responsive-scroll>.p-datatable-wrapper {
  // padding-bottom: 10px;
  min-height: 80vh !important;

  &::-webkit-scrollbar {
    width: 1px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(136, 136, 136, 0.366);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer !important;
  }
}

// .p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
//   padding: 0.4rem;
//   font-size: 0.8rem;
// }
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th, .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.4rem;
  font-size: 0.8rem;
}

@media only screen and (min-width: 992px) {
  .sticky-thead .p-datatable-wrapper {
    overflow-x: unset !important;

} 
.sticky-thead-withoutheader  .p-datatable-wrapper {
  overflow-x: unset !important;

} 

.sticky-thead thead {
    position: sticky;
    top: 152px;
    
    // z-index: 5555;
}
.sticky-thead-withoutheader thead {
  position: sticky;
  top: 97px;
  // z-index: 5555;
}
}
.p-paginator .p-paginator-current {
  color: var(--mainColor);
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: var(--mainColor);
  min-width: 2rem;
  height: 2rem;
}

.p-paginator {
  .p-dropdown {
    height: 30px;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--whiteColor);
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background-color: var(--mainColor);
}

.p-button.p-button-outlined {
  color: var(--mainColor);
  border-color: var(--mainColor);

  &:enabled:hover {
    color: var(--mainColor);
    border-color: var(--mainColor);
    // background-color: rgba(25, 222, 222, 0.0941176471);
  }
}

.p-paginator {
  .p-paginator-pages {
    .p-paginator-page {
      color: var(--mainColor);
      height: 2rem !important;
      min-width: 2rem !important;

      &.p-highlight {
        background-color: var(--mainColor);
        border-color: var(--mainColor);
        color: var(--whiteColor);
      }
    }
  }
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: 0 0 0 0.2rem $shadow-color;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  color: var(--btn-hover);
}

.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  color: var(--btn-hover);
}

.p-button.p-button-text {
  color: var(--mainColor);

  &:enabled:hover {
    color: var(--mainColor);
    // background-color: #19dede18;
  }
}

.p-dropdown {
  width: 100%;
  height: 30px;
  background-color: #f5f5f5;
  border: transparent;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.15rem 0.15rem !important;
}

.p-dropdown .p-dropdown-label.p-placeholder,
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #7d93b2 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.p-dropdown-filter {
  height: 30px !important;
}

.p-dropdown-label {
  display: flex !important;
  width: 100% !important;
  align-items: center;
}

.p-dropdown-panel {
  z-index: 2 !important;

  .p-dropdown-items {
    .p-dropdown-item {
      &.p-highlight {
        background-color: var(--mainColor);
      }
    }
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem $shadow-color !important;
  border-color: var(--mainColor);
}

.p-link:focus {
  box-shadow: 0 0 0 0.2rem $shadow-color !important;
}

.p-datepicker {
  .p-datepicker-header {

    .p-datepicker-prev:focus,
    .p-datepicker-next:focus {
      box-shadow: 0 0 0 0.2rem #0ec6c647;
    }

    .p-datepicker-title {
      line-height: normal;
      display: flex;

      .p-datepicker-month:enabled:hover,
      .p-datepicker-year:enabled:hover {
        color: #ffffff;
        background-color: var(--mainColor);
      }
    }
  }

  table td>span.p-highlight {
    background-color: var(--mainColor) !important;
  }

  table td.p-datepicker-today>span.p-highlight {
    background-color: var(--mainColor) !important;
  }

  box-shadow: 0 0 0 0.2rem $shadow-color !important;
}

.p-datatable .p-sortable-column {
  &.p-highlight {
    color: var(--mainColor);

    &:hover {
      color: var(--mainColor);

      .p-sortable-column-icon {
        color: var(--mainColor);
      }
    }

    .p-sortable-column-icon {
      color: var(--mainColor);
    }
  }

  &:focus {
    box-shadow: inset 0 0 0 0.15rem $shadow-color;
  }
}

// .p-datatable .p-sortable-column.p-highlight:hover .p-toast .p-toast-message .p-toast-message-content {
//   display: flex;
//   align-items: center;

//   .p-toast-detail {
//     margin: 0;
//   }
// }

.p-toast {
  .p-toast-message {
    .p-toast-message-content {
      align-items: center;

      .p-toast-detail {
        margin: 0 !important;
      }

      .p-toast-message-icon {
        font-size: 1.4rem;
      }
    }
  }
}

@media (max-width: 575.5px) {
  .p-toast .p-toast-message {
    width: 100%;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--mainColor) !important;
  background: var(--mainColor) !important;
}

// .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem $shadow-color !important;
  border-color: var(--mainColor) !important;
}

// &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
//   box-shadow: 0 0 0 0.2rem $shadow-color !important;
// }
// }

.p-input-icon-left>.p-inputtext {
  padding-left: 2.5rem;
  height: 30px;
  background: #f9f9f9 !important;
  transition: var(--transition);
  border-radius: 5px;
  border: 1px solid #e5e7f2 !important;
  color: var(--blackColor) !important;
  font-weight: 400;
  font-size: var(--fontSize);
  padding-top: 0;
  padding-bottom: 0;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem $shadow-color !important;
  border-color: var(--mainColor);
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem $shadow-color !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  text-align: center;
}

// .p-calendar .p-datepicker {
//   z-index: 1000 !important;
// }
.p-multiselect .p-multiselect-label {
  padding: 0 5px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: #f5f5f5 !important;
}

.p-multiselect .p-multiselect-trigger {
  background-color: #f5f5f5 !important;
}

.p-calendar-w-btn {
  .p-inputtext {
    height: 30px;
    // margin: 0.3rem 0 0 0;
    background-color: #f5f5f5 !important;
    border: none;
  }

  .p-datepicker-trigger {
    background-color: var(--buttonColor);
    border-color: var(--buttonColor);
    height: 30px;
    color: white;

    &:hover {
      background-color: var(--buttonColor);
      border-color: var(--buttonColor);
    }
  }
}

// .p-button:focus {
//   box-shadow: 0 0 0 0.2rem $shadow-color !important;
// }

.p-button.p-button-icon-only {
  width: auto;
  padding: 0.5rem;
}

.p-calendar {
  min-width: 100%;

  .p-datepicker {
    z-index: 2 !important;
  }
}

.p-datatable-responsive-scroll>.p-datatable-wrapper {
  // min-height: 400px !important;
  // max-height: 400px !important;
}

.p-calendar.p-calendar-clearable .p-inputtext,
.p-calendar-w-btn .p-datepicker-trigger {
  height: 30px !important;
  // margin: 0.3rem 0 0 0;
}

.p-button.p-button-rounded {
  display: flex;
  height: 2.357rem !important;
  justify-content: center;
  width: 2.357rem !important;

  .p-button-label {
    display: none;
  }
}

.p-calendar-w-btn .p-datepicker-trigger {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-button {
  height: 30px;
  background-color: var(--buttonColor);
  border-color: var(--buttonColor);
  white-space: nowrap;

  &:enabled:hover {
    background-color: var(--buttonColor);
    border-color: var(--buttonColor);
    color: var(--whiteColor);
  }
}

.p-datepicker table th {
  text-align: center !important;
}

// validation Css
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border: 1px solid #dc3545 !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem #dc35466e;
}

// Form and Layout Css
select {
  display: block;
  height: 30px !important;
  line-height: 30px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: var(--transition);
  border: 1px solid #e5e7f2;
  color: #7d93b2;
  width: 100%;
  padding-left: 15px;

  font: {
    weight: 400;
    size: var(--fontSize);
  }

  &:focus {
    border-color: var(--mainColor);
    background-color: transparent;
  }
}

.add-listings-box {
  margin-bottom: 10px;
  background-color: var(--whiteColor);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 15px 25px 15px 25px !important;

  .theader {
    display: flex;
    width: 100%;
    border-bottom: 1px dashed #eeeeee;
    margin-bottom: 10px;
    padding-inline: 10px;
    align-items: center;
    justify-content: space-around;
    padding: 10px;

    h3 {
      // padding: 10px;
      padding: 10px 10px 0 15px;
      // border-bottom: 1px dashed #eeeeee;
      width: 100%;

      font: {
        size: 17px;
        weight: 600;
      }

      margin: {
        bottom: 10px;
        left: -25px;
        right: -25px;
      }
    }
  }

  h3 {
    padding: 10px;
    border-bottom: 1px dashed #eeeeee;

    font: {
      size: 17px;
      weight: 600;
    }

    margin: {
      bottom: 10px;
      // left: -25px;
      // right: -25px;
    }
  }

  .form-group {
    margin-bottom: 25px;
    position: relative;

    label {
      display: block;
      margin-bottom: 5px;
      color: var(--blackColor);
      position: relative;
      // padding-left: 25px;
      font-weight: 500;

      i {
        color: var(--mainColor);
        position: absolute;
        left: 0;
        top: 0.5px;
        font-size: 19px;

        &.bxl-facebook-square {
          color: #3b5998;
        }

        &.bxl-twitter {
          color: #1da1f2;
        }

        &.bxl-linkedin {
          color: #0077b5;
        }
      }

      span {
        color: var(--optionalColor);
        font-weight: 400;
      }
    }

    .form-control {
      background-color: #f9f9f9;
      transition: none !important;
      // transition: var(--transition);
      border-radius: 5px;
      border: 1px solid #e5e7f2;
      margin-top: 5px;
      color: var(--blackColor);

      font: {
        weight: 400;
        size: var(--fontSize);
      }

      padding: {
        top: 0;
        bottom: 0;
        right: 15px;
        left: 15px;
      }

      &::placeholder {
        transition: var(--transition);
        color: #7d93b2;
      }

      &:focus {
        border-color: var(--mainColor);
        // box-shadow: 0 0 0 0.2rem $shadow-color !important;

        &::placeholder {
          color: transparent;
        }
      }
    }

    select {
      display: block;
      height: 30px !important;
      line-height: 30px;
      border-radius: 5px;
      cursor: pointer;
      background-color: #f9f9f9;
      transition: var(--transition);
      border: transparent;
      color: #7d93b2;
      width: 100%;
      padding-left: 15px;

      font: {
        weight: 400;
        size: var(--fontSize);
      }

      &:focus {
        border-color: var(--mainColor);
        background-color: transparent;
      }
    }

    .facilities-list {
      padding-left: 0;
      margin-bottom: -15px;
      list-style-type: none;
      overflow: hidden;

      li {
        float: left;
        margin-right: 20px;
        position: relative;
        margin-bottom: 15px;

        .checkbox {
          margin-bottom: 0;
          padding-left: 0;
          cursor: pointer;
          display: inline-block;

          input {
            display: none;
          }

          input+span {
            margin-top: 10px;
            line-height: 20px;
            height: 20px;
            padding-left: 20px;
            display: block;
            position: relative;
            font-weight: 500;
          }

          input+span:not(:empty) {
            padding-left: 28px;
          }

          input+span:before,
          input+span:after {
            content: "";
            width: 20px;
            height: 20px;
            display: block;
            border-radius: 50%;
            left: 0;
            top: -1px;
            position: absolute;
          }

          input+.check-box:before,
          input+.check-box:after {
            border-radius: 10%;
          }

          input+span:before {
            background: #eeeeee;
            transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2),
              -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
          }

          input+span:after {
            background: #eeeeee;
            transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4),
              -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
          }

          input:checked+span:before {
            background: var(--mainColor);
          }

          input:checked+span:after {
            transform: scale(0.4);
            transition: transform 0.3s ease, -webkit-transform 0.3s ease;
          }
        }
      }
    }

    .sidebar-widgets {
      .box {
        background: #f6f6f6;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 20px;
        position: relative;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          display: block;
          color: var(--blackColor);
          position: relative;
          font-weight: 600;
        }

        .switch {
          padding-left: 0;
          margin-bottom: 0;
          display: inline-block;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;

          input {
            display: none;
          }

          input+span {
            width: 48px;
            height: 28px;
            border-radius: 14px;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            display: block;
            position: relative;
            background: var(--mainColor);
            box-shadow: 0 8px 16px -1px rgba(8, 141, 211, 0.2);
          }

          input+span:before,
          input+span:after {
            content: "";
            display: block;
            position: absolute;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }

          input+span:before {
            top: 5px;
            left: 5px;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            border: 5px solid var(--whiteColor);
          }

          input+span:after {
            top: 5px;
            left: 32px;
            width: 6px;
            height: 18px;
            border-radius: 40%;
            transform-origin: 50% 50%;
            background: var(--whiteColor);
            opacity: 0;
          }

          input+span:active {
            transform: scale(0.92);
          }

          input:checked+span {
            background: #48ea8b;
            box-shadow: 0 8px 16px -1px rgba(72, 234, 139, 0.2);
          }

          input:checked+span:before {
            width: 0px;
            border-radius: 3px;
            margin-left: 27px;
            border-width: 3px;
            background: var(--whiteColor);
          }

          input:checked+span:after {
            animation: blobChecked 0.35s linear forwards 0.2s;
          }

          input:not(:checked)+span:before {
            animation: blob 0.85s linear forwards 0.2s;
          }
        }
      }
    }
  }
}

// minus button css
.remove {
  .p-button {
    // button {

    background-color: #eb4444 !important;
    border-color: #eb4444 !important;

    // }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(198, 14, 14, 0.28) !important;
    }
  }
}

.Master-view-box {
  margin-bottom: 10px;
  background-color: var(--whiteColor);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 0 25px 0 25px;

  .theader {
    display: flex;
    justify-content: space-between !important;
    // width: 100%;
    border-bottom: 1px dashed #eeeeee;
    margin-bottom: 10px;
    padding-inline: 10px;
    align-items: center;
    // justify-content: space-around;
    padding: 10px;

    h3 {
      // padding: 10px;
      padding: 10px 10px 0 15px;

      // border-bottom: 1px dashed #eeeeee;
      // width: 100%;
      font: {
        size: 17px;
        weight: 600;
      }

      margin: {
        bottom: 10px;
        left: -25px;
        right: -25px;
      }
    }
  }

  .form-group {
    margin-bottom: 25px;
    position: relative;

    label {
      display: block;
      margin-bottom: 8px;
      color: var(--blackColor);
      position: relative;
      // padding-left: 25px;
      font-weight: 500;

      i {
        color: var(--mainColor);
        position: absolute;
        left: 0;
        top: 0.5px;
        font-size: 19px;

        &.bxl-facebook-square {
          color: #3b5998;
        }

        &.bxl-twitter {
          color: #1da1f2;
        }

        &.bxl-linkedin {
          color: #0077b5;
        }
      }

      span {
        color: var(--optionalColor);
        font-weight: 400;
      }
    }

    .form-control {
      height: 30px;
      background-color: #f9f9f9;
      transition: var(--transition);
      border-radius: 5px;
      border: 1px solid #e5e7f2;
      color: var(--blackColor);

      font: {
        weight: 400;
        size: var(--fontSize);
      }

      padding: {
        top: 0;
        bottom: 0;
        right: 15px;
        left: 15px;
      }

      &::placeholder {
        transition: var(--transition);
        color: #7d93b2;
      }

      &:focus {
        border-color: var(--mainColor);

        &::placeholder {
          color: transparent;
        }
      }
    }

    textarea.form-control {
      padding-top: 15px;
      // height: auto;
    }

    select {
      display: block;
      height: 30px;
      line-height: 30px;
      border-radius: 5px;
      cursor: pointer;
      background: #f9f9f9;
      transition: var(--transition);
      border: 1px solid #e5e7f2;
      color: #7d93b2;
      width: 100%;
      padding-left: 15px;

      font: {
        weight: 400;
        size: var(--fontSize);
      }

      &:focus {
        border-color: var(--mainColor);
        background-color: transparent;
      }
    }

    .facilities-list {
      padding-left: 0;
      margin-bottom: -15px;
      list-style-type: none;
      overflow: hidden;

      li {
        float: left;
        margin-right: 20px;
        position: relative;
        margin-bottom: 15px;

        .checkbox {
          margin-bottom: 0;
          padding-left: 0;
          cursor: pointer;
          display: inline-block;

          input {
            display: none;
          }

          input+span {
            line-height: 20px;
            height: 20px;
            padding-left: 20px;
            display: block;
            position: relative;
            font-weight: 500;
          }

          input+span:not(:empty) {
            padding-left: 28px;
          }

          input+span:before,
          input+span:after {
            content: "";
            width: 20px;
            height: 20px;
            display: block;
            border-radius: 50%;
            left: 0;
            top: -1px;
            position: absolute;
          }

          input+span:before {
            background: #eeeeee;
            transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2),
              -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
          }

          input+span:after {
            background: #eeeeee;
            transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4),
              -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
          }

          input:checked+span:before {
            background: var(--mainColor);
          }

          input:checked+span:after {
            transform: scale(0.4);
            transition: transform 0.3s ease, -webkit-transform 0.3s ease;
          }
        }
      }
    }

    .sidebar-widgets {
      .box {
        background: #f6f6f6;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 20px;
        position: relative;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          display: block;
          color: var(--blackColor);
          position: relative;
          font-weight: 600;
        }

        .switch {
          padding-left: 0;
          margin-bottom: 0;
          display: inline-block;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;

          input {
            display: none;
          }

          input+span {
            width: 48px;
            height: 28px;
            border-radius: 14px;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            display: block;
            position: relative;
            background: var(--mainColor);
            box-shadow: 0 8px 16px -1px rgba(8, 141, 211, 0.2);
          }

          input+span:before,
          input+span:after {
            content: "";
            display: block;
            position: absolute;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }

          input+span:before {
            top: 5px;
            left: 5px;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            border: 5px solid var(--whiteColor);
          }

          input+span:after {
            top: 5px;
            left: 32px;
            width: 6px;
            height: 18px;
            border-radius: 40%;
            transform-origin: 50% 50%;
            background: var(--whiteColor);
            opacity: 0;
          }

          input+span:active {
            transform: scale(0.92);
          }

          input:checked+span {
            background: #48ea8b;
            box-shadow: 0 8px 16px -1px rgba(72, 234, 139, 0.2);
          }

          input:checked+span:before {
            width: 0px;
            border-radius: 3px;
            margin-left: 27px;
            border-width: 3px;
            background: var(--whiteColor);
          }

          input:checked+span:after {
            animation: blobChecked 0.35s linear forwards 0.2s;
          }

          input:not(:checked)+span:before {
            animation: blob 0.85s linear forwards 0.2s;
          }
        }
      }
    }
  }
}

// submit and draft button css
.add-listings-btn {
  float: right;
  // margin-top: 1.3rem;

  button {
    display: inline-block;
    background-color: var(--buttonColor);
    color: var(--whiteColor);
    padding: 5px 25px;
    float: right !important;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    transition: var(--transition);
    border: none;

    font: {
      size: var(--fontSize);
      weight: 600;
    }

    &:hover {
      // background-color: var(--mainColor);
      color: var(--whiteColor);
    }

    &:disabled {
      background-color: grey;
    }
  }

  // &:hover {
  //   background-color: var(--blackColor);
  //   color: var(--whiteColor);
  // }
}

.buttons {
  justify-content: space-between;

  .draft-btn {
    float: left;

    button {
      display: inline-block;
      background-color: var(--buttonColor);
      color: var(--whiteColor);
      padding: 5px 25px;
      float: right !important;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      transition: var(--transition);
      border: none;

      font: {
        size: var(--fontSize);
        weight: 600;
      }

      &:hover {
        // background-color: var(--mainColor);
        color: var(--whiteColor);
      }

      &:disabled {
        background-color: grey;
      }
    }

    // &:hover {
    //   background-color: var(--blackColor);
    //   color: var(--whiteColor);
    // }
  }
}

// }  error 3

/* Max width 767px */

@media only screen and (max-width: 767px) {
  .add-listings-box {
    padding: 0 20px 0 20px;

    h3 {
      padding: 15px 20px;

      margin: {
        bottom: 20px;
        left: -20px;
        right: -20px;
      }
    }

    .form-group {
      label {
        padding-left: 21px;

        i {
          font-size: 16px;
        }
      }

      .form-control {
        height: 30px;
        font-size: 13.5px;

        padding: {
          right: 10px;
          left: 10px;
        }
      }

      // textarea.form-control {
      //   padding-top: 10px;
      // }

      select {
        height: 30px;
        line-height: 30px;
        font-size: 13.5px;

        padding: {
          right: 10px;
          left: 10px;
        }
      }

      .sidebar-widgets {
        .box {
          padding: 15px 10px;
        }
      }
    }

    .opening-day {
      select {
        margin-top: 15px;
        height: 30px;
        line-height: 30px;
        font-size: 13.5px;

        padding: {
          right: 10px;
          left: 10px;
        }
      }
    }
  }
}

/* Min width 576px to Max width 767px */

@media only screen and (min-width: 576px) and (max-width: 767px) {}

/* Min width 768px to Max width 991px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .add-listings-box {
    .opening-day {
      h5 {
        margin-bottom: 15px;
      }
    }
  }
}

/* Min width 992px to Max width 1199px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

/* Min width 1200px to Max width 1355px */

@media only screen and (min-width: 1200px) and (max-width: 1355px) {}

/* Min width 1550px */

// button {
//   display: block;
//   background-color: var(--mainColor);
//   color: var(--whiteColor);
//   border: none;
//   font-weight: 600;
//   display: block;
//   // width: 20%;
//   border-radius: 5px;
//   padding: 14px 30px 13px;
//   transition: var(--transition);
//   &:hover {
//     background-color: var(--blackColor);
//     color: var(--whiteColor);
//   }
// }

.form-check-input:checked {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem $shadow-color;
}

.header {
  // margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 22px;

  h3 {
    font-weight: 600;
  }
}

// status button css
.status {
  .approved {
    border-radius: 30px;
    color: var(--whiteColor);
    padding: 3px 43.71px;
    position: relative;
    top: -2px;
    margin-left: 2px;
    font-size: 12px;
    background-color: #64bc36;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }
  .terminated{
    border-radius: 30px;
    padding: 3px 40.5px;
    position: relative;
    background-color: #1d3b55;
    color: var(--whiteColor);
    top: -2px;
    margin-left: 2px;
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;
  }
  .renewed {
    border-radius: 30px;
    color: var(--whiteColor);
    padding: 3px 43.71px;
    position: relative;
    top: -2px;
    margin-left: 2px;
    font-size: 12px;
    background-color: #438146;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }
  .due {
    border-radius: 30px;
    color: var(--whiteColor);
    padding: 3px 43.71px;
    position: relative;
    top: -2px;
    margin-left: 2px;
    font-size: 12px;
    background-color: #efc32e;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }

  .verify {
    border-radius: 30px;
    color: var(--whiteColor);
    padding: 3px 49.25px;
    position: relative;
    top: -2px;
    margin-left: 2px;
    font-size: 12px;
    background-color: #229fc5;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }
.done{
  border-radius: 30px;
    padding: 3px 56.33px;
    position: relative;
    background-color: #64bc36;
    color: var(--whiteColor);
    top: -2px;
    margin-left: 2px;
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;
}
  .on-hold {
    border-radius: 30px;
    color: var(--whiteColor);
    padding: 3px 20px;
    position: relative;
    top: -2px;
    margin-left: 2px;
    font-size: 12px;
    background-color: #6c757d;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }

  .pending {
    border-radius: 30px;
    color: var(--whiteColor);
    padding: 3px 51.2px;
    position: relative;
    top: -2px;
    margin-left: 2px;
    font-size: 12px;
    background-color: #e5952e;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }
  .draft {
    border-radius: 30px;
    color: var(--whiteColor);
    padding: 3px 56.53px;
    position: relative;
    top: -2px;
    margin-left: 2px;
    font-size: 12px;
    background-color: #8a8a8a;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }

  .in-progress {
    border-radius: 30px;
    color: var(--whiteColor);
    padding: 3px 40.33px;
    position: relative;
    top: -2px;
    margin-left: 2px;
    font-size: 12px;
    background-color: #037cd5;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }

  .top-priority {
    border-radius: 30px;
    padding: 3px 35px;
    position: relative;
    background-color: red;
    color: var(--whiteColor);
    top: -2px;
    margin-left: 2px;
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }

  .veri-rejected {
    border-radius: 30px;
    padding: 3px 14px;
    position: relative;
    background-color: rgb(235, 70, 70);
    color: var(--whiteColor);
    top: -2px;
    margin-left: 2px;
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }

  .rejected {
    border-radius: 30px;
    padding: 3px 46.5px;
    position: relative;
    background-color: rgb(235, 70, 70);
    color: var(--whiteColor);
    top: -2px;
    margin-left: 2px;
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }

  .moderate-priority {
    border-radius: 30px;
    padding: 3px 19px 3.5px;
    position: relative;
    background-color: rgba(52, 52, 245, 0.566);
    color: var(--whiteColor);
    top: -2px;
    margin-left: 2px;
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }

  .low-priority {
    border-radius: 30px;
    padding: 3px 35px 3.5px;
    position: relative;
    background-color: green;
    color: var(--whiteColor);
    top: -2px;
    margin-left: 2px;
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }

  .no-priority {
    border-radius: 30px;
    padding: 3px 16px 3.5px;
    position: relative;
    background-color: #4b84f9;
    color: var(--whiteColor);
    top: -2px;
    margin-left: 2px;
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;

    label {
      margin-bottom: 0 !important;
      color: var(--whiteColor) !important;
    }
  }
}

@media only screen and (min-width: 1550px) {}

// red border for prime ng input
// p-inputnumber.ng-dirty.ng-invalid,
// p-inputnumber.ng-touched.ng-invalid {
//   border-radius: 5px;
//   border: 1px solid #dc3545 !important;
// }
p-inputnumber span {
  height: 30px;
}

.p-multiselect:not(.p-disabled) {
  border: unset;
}

.p-multiselect:not(.p-disabled):hover {
  border: 1px solid var(--mainColor);
}

.p-multiselect-label-container {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

p-inputnumber .p-inputtext {
  width: 100%;
}

// select.ng-dirty.ng-invalid ,
// select.ng-touched.ng-invalid {
//     border-radius: 5px;
//     border: 1px solid #dc3545 !important;
//     // background: rgba(255, 0, 0, 0.35);
//     // box-shadow: 0 0 5px 1px rgba(255, 0, 0, 0.2) inset !important;
// }

.add-btn {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.error {
  border: 1px solid #dc3545 !important;
  border-radius: 5px;
}

select .p-inputtext {
  width: 100%;
}



#myImg {
  cursor: pointer;
}

:host ::ng-deep {
  .p-fluid .p-inputtext {
    height: 30px;
  }

  .p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
    padding: 0.1rem 0.3rem;
  }

  .p-button.p-button-icon-only.p-button-rounded {
    height: 1.8rem;
    width: 1.8rem;
  }

  .p-datatable-wrapper {
    min-height: 350px;
  }
}

.p-editor-container .p-editor-content .ql-editor {
  height: 150px;
}

.p-button:focus {
  box-shadow: none !important;
}

// tooltip font-size
.p-tooltip {
  font-size: 12px !important;
  // width: 1800px !important;
}

// .p-tooltip .p-tooltip-text {
//   white-space: normal !important;
//   width: max-content !important;
//   word-break: break-all;
// }

.p-multiselect {
  // max-width: 22rem;
  // min-width: 22rem;
  width: 100%;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #000000;
  // background: #0ec6c621;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  // background: #e0f8f8;
  color: #000000;
  border-radius: 4px;
  font-size: 0.8rem;
}

.pi {
  font-size: 0.8rem;
}

// .p-checkbox .p-checkbox-box .p-checkbox-icon {
//   font-size: 8px;
// }

.p-checkbox .p-checkbox-box {
  width: 20px;
  height: 20px;
  border-radius: 3px;

  // &:hover {
  // }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--mainColor);
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 12px;
  font-weight: 900;
}

.p-multiselect .p-multiselect-panel {
  z-index: 99 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.15rem $shadow-color;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.15rem 0.75rem;
}

// payment section css
.payment-totals {
  background: var(--whiteColor);
  padding: 40px;
  max-width: 500px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  margin: {
    top: 60px;
    left: auto;
    right: auto;
  }

  h3 {
    margin-bottom: 25px;
    font-size: 22px;
  }

  ul {
    padding-left: 0;
    margin: 0 0 25px;
    list-style-type: none;

    li {
      // border: 1px solid #eaedff;
      padding: 10px 15px;
      color: var(--blackColor);
      overflow: hidden;

      font: {
        weight: 600;
        size: var(--fontSize);
      }

      &:first-child {
        border: {
          bottom: none;
        }
      }

      &:last-child {
        font-size: 19px;

        border: {
          top: none;
        }

        span {
          color: var(--blackColor);
          font-weight: 700;
        }
      }

      span {
        float: right;
        color: var(--optionalColor);

        font: {
          weight: normal;
        }
      }
    }
  }
}

:host ::ng-deep .p-message-custom {
  background-color: #e1cfe7;
  border: solid #8a427a;
  border-width: 0 0 0 6px;
  color: #2c1e30;
}

:host ::ng-deep .p-message-close-icon {
  color: #2c1e30;
}

// .form-control {
//   border: unset;
// }
@media only screen and (max-width: 520px) {
  .Master-view-box {
    .theader {
      flex-direction: column;
    }
  }
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.submit_btn {
  // background-color: var(--mainColor);
  // color: var(--whiteColor) !important;
  // font-size: var(--fontSize) !important;
  // transition: var(--transition) !important;
  // border-color: transparent !important;
  // border-radius: 25px;
}

.alert.alert-danger {
  width: 100%;
  // margin-top: 0.25rem;
  font-size: 0.875em;
  color:  #8f1b26  !important;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 0;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}

.invalid-feedback {
  font-weight: 500;
  color: #dc3545;
}

option {
  color: var(--blackColor) !important;
}

option:disabled {
  color: gray !important;
  opacity: 0.5;
  text-decoration: line-through !important;
}

/*------ Dhruv Css Start ------*/

// Status Styles
// .pending {
//   background-color: red;
//   border-radius: 30px;
//   padding: 3px 10px;
//   color: #fff;
//   text-align: center;
// }

// .in-progress {
//   background-color: orange;
//   border-radius: 30px;
//   padding: 3px 10px;
//   color: #fff;
//   text-align: center;
// }

// .approved {
//   background-color: green;
//   border-radius: 30px;
//   padding: 3px 10px;
//   color: #fff;
//   text-align: center;
// }

.alert-danger {
  color: #dc3545 !important;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background-color: var(--mainColor);
  color: #fff;
}

/* Badges Styling */

$success: darkgreen;
// $info: darkgray;
$pending: red;

@mixin badge($badge: var(--mainColor)) {
  background: var(--mainColor);
  box-shadow: 0 0 1px rgba($badge, 0.25);
  color: #fff;
  padding: 2px 10px;
  border-radius: 30px;
  font-size: 1rem;
}

.pending {
  @include badge($badge: $pending);
}

.in-progress {
  @include badge;
}

.approve {
  @include badge($badge: $success);
}

.capitalize {
  text-transform: capitalize !important;
}

.table {
  border: $border;
  border-radius: 5px;

  .row {
    --bs-gutter-x: 0;

    .table-cols {
      border-bottom: $border !important;

      &:last-child {
        border-bottom: none;
      }

      &:nth-of-type(odd) {
        border-right: none;
      }

      &:nth-of-type(even) {
        border-right: none;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.center-text {
  text-align: center !important;
}

.view-buttons {
  display: flex;
  align-items: center;

  .p-button {
    padding: 0.2rem !important;
  }
}

.allSection {
  margin-top: 0px;
  // margin-top: 115px;
}

.homeBUtton {
  background:  var(--redColor);
  border-radius: 10px;
  font-size: 18px;
  color: white;
  padding: 5px 0px;
  border: none;
  min-width: 180px;
}

.homeButtonBlue {
  background: var(--buttonColor);
  border-radius: 10px;
  font-size: 18px;
  color: white;
  padding: 5px 0px;
  border: none;
  min-width: 180px;
}

@media (max-width: 1399px) {
  .allSection {
    margin-top: 0px;
    // margin-top: 97.84px;
  }
}

@media (max-width: 1299px) {
  .homeBUtton {
    border-radius: 6px;
    font-size: 18px;
    padding: 5px;
    min-width: 170px;
  }

  .homeButtonBlue {
    border-radius: 6px;
    font-size: 18px;
    padding: 5px;
    min-width: 170px;
  }
}

@media (max-width: 1023px) {
  .homeBUtton {
    font-size: 15px;
    min-width: 150px;
  }

  .homeButtonBlue {
    font-size: 15px;
    min-width: 150px;
  }
}

.addSection {
  background-color: var(--mainColor) !important;
}

.p-datepicker table td>span.p-highlight {
  color: #ffffff !important;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
  color: #ffffff;
}

@media (max-width: 525px) {
  .allSection {
    // margin-top: 77.14px;
    margin-top: 0px;
  }
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  color: #fff;
  background-color: var(--mainColor);
}

.btn-toolbar {
  display: none !important;
}

.btn-toolbar ng-wizard-toolbar {
  display: none !important;
}

.p-multiselect-filter-container .p-inputtext {
  height: 30px;
}

.field_Controls {
  background-color: var(--mainColor) !important;
}

.success-btn {
  background-color: #22C55E !important;
}

/*------ Dhruv Css Ends ------*/
.reject-btn {
  background-color: #EF4444 !important;
}

.draft-btn {
  background-color: #e5952e !important;
}
.p-paginator .p-dropdown {
  height: 30px;
  z-index: 2;
}
.dropdown-large .p-component.p-dropdown {
  height: 52px !important;
}

.p-datatable-table td {
  font-size: 0.8rem;
}
.table-auto .p-datatable-wrapper {
  overflow-x: auto !important;
}


@media all and (min-width:1399px){
  .sticky-thead-withoutheader thead {
    // position: sticky;
    top: 114px;
}

}

.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: auto !important;
}

.p-datatable {
  .p-paginator-bottom {
    border-width: 0; 
  }
}

.simplebar-track.simplebar-vertical {
  width: 10px;
  .simplebar-scrollbar{
    width: 8px;
    &:before{
      width: 8px;
      background:#999;
    }
  }
  &.simplebar-hover{
    .simplebar-scrollbar{
      &:before{
        background: #444;
      }
    }
  }
}


.switch-light span span {
  display: none;
}

@media only screen {
  .switch-light {
    margin-left: 10px;
    position: relative;
    display: block;
  }
  .switch-light span{
    white-space: nowrap;
  }
  .switch-light::after {
    clear: both;
    content: "";
    display: table;
  }
  .switch-light *,
  .switch-light *:before,
  .switch-light *:after {
    box-sizing: border-box;
  }
  .switch-light a {
    display: block;
    transition: all 0.2s ease-out;
  }
  .switch-light label,
  .switch-light > span {
    line-height: 1.6em;
  }
}
@media only screen {
  .switch-light input {
    position: absolute;
    opacity: 0;
    z-index: 3;
  }
  .switch-light input:checked ~ span a {
    right: 0%;
  }
  .switch-light strong {
    font-weight: inherit;
  }
  .switch-light > span {
    position: relative;
    overflow: hidden;
    display: block;
    min-height: 2em;
    padding: 0;
    text-align: left;
  }
  .switch-light span span {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    width: 50%;
    text-align: center;
    user-select: none;
    font-weight: 600;
  }
  .switch-light a {
    position: absolute;
    right: 50%;
    top: 0;
    z-index: 1;
    display: block;
    width: 50%;
    height: 100%;
    padding: 0;
    border-radius: 50px;
    background-color: #fff;
    outline: none;
  }

@media only screen {
  .switch-light.switch-candy > span {
    background-color:  var(--buttonColor);
    border: 5px solid  var(--buttonColor);
    border-radius: 50px;
  }
  .switch-light.switch-candy span span,
  .switch-light.switch-candy input:checked ~ span span:first-child {
    color: rgba(255,255,255,0.8);
    text-align: center;
    padding: 5px 35px;
    cursor: pointer;
  }
  .switch-light.switch-candy input ~ span span:first-child,
  .switch-light.switch-candy input:checked ~ span span:nth-child(2),
  .switch-candy input:checked + label {
    color:  var(--buttonColor);
  }
  .switch-light a:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 10px;
    border-bottom: 2px solid  var(--buttonColor);
    border-right: 2px solid  var(--buttonColor);
    transform: rotate(50deg);
    margin:12px 0 0 20px;
    opacity: 0.5;
  }
}

@media only screen and (-webkit-max-device-pixel-ratio: 2) and (max-device-width: 80em) {
  .switch-light{
    -webkit-animation: webkitSiblingBugfix infinite 1s;
  }
}
@-webkit-keyframes webkitSiblingBugfix {
  from {
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
  }
}
}

@media (max-width:767px){
  .switchToggle {
    h6{
      margin-bottom: 5px !important;
        width: 100%;
  }
  .switch-light{
    margin-left: 0;

  }
}
}
.p-button-icon-only{
  background: white;
    color: var(--buttonColor);
}

.p-datatable-scrollable .p-datatable-thead, .p-datatable-scrollable .p-datatable-tbody, .p-datatable-scrollable .p-datatable-tfoot {
  display: table-row-group !important;
}

.p-datatable-scrollable .p-datatable-thead>tr, .p-datatable-scrollable .p-datatable-tbody>tr, .p-datatable-scrollable .p-datatable-tfoot>tr {

  display: table-row !important;
}

.p-datatable-scrollable .p-datatable-thead>tr>th, .p-datatable-scrollable .p-datatable-tbody>tr>td, .p-datatable-scrollable .p-datatable-tfoot>tr>td {
  display: table-cell !important;
  flex: unset !important;
}
.p-datatable-scrollable .p-datatable-thead>tr>th.hide, .p-datatable-scrollable .p-datatable-tbody>tr>td.hide {
  display: none !important;
}

.hide {
  display: none !important;
}

.nowrap{
  white-space: nowrap;
}

/* width */
.p-datatable-responsive-scroll>.p-datatable-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.p-datatable-responsive-scroll>.p-datatable-wrapper::-webkit-scrollbar-track {
  background:transparent;
}

/* Handle */
.p-datatable-responsive-scroll>.p-datatable-wrapper::-webkit-scrollbar-thumb {
  background: #c6c6c6;
}

/* Handle on hover */
.p-datatable-responsive-scroll>.p-datatable-wrapper::-webkit-scrollbar-thumb:hover {
  background: #adadad;
}
.btn-danger{
  --bs-btn-bg:var(--redColor);
  --bs-btn-border-color:var(--redColor);
  --bs-btn-hover-bg:#c90d10;
}

.btn-outline-danger{
  --bs-btn-color:var(--redColor);
  --bs-btn-border-color:var(--redColor);
  --bs-btn-hover-bg:var(--redColor);
}
.btn-outline-danger:focus{
  background: #fff;
  color:var(--redColor);
}

.btn-danger:focus,.btn-outline-danger:focus{
  outline: none !important;
  box-shadow: none !important;
}
.ui-calendar {
  z-index: 1050 !important;
}